import React from 'react'
import './home.css'

const About = () => {
    return (
        <div style={{ height: '80vh' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '120px' }}>
                <h1 style={{ marginBottom: '120px' }}>About</h1>
                <p className='text-center' style={{ marginLeft: '20px', marginRight: '20px' }}>
                    Temp-mail.space is a free service that offers temporary, disposable mailboxes to receive emails from. <br />
                    Bear in mind, emails can be accessed by anyone that knows your address. Join Our Telegram Channel <a style={{ textDecoration: 'none', color: 'red' }} target='_blank' href="https://t.me/+QGWFuGu8sH0wYTYy" rel='noreferrer'>here</a>.
                </p>
            </div>
        </div>
    )
}

export default About
