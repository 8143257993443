import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import './bootstrap.min.css'

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
